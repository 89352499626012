<template>
    <div class="pipedrive-page">
        <div class="pipedrive-page__inner">
            <v-sheet
                rounded="lg"
                color="white"
                class="pa-8"
            >
                <h1 class="text-h5 font-weight-medium">
                    Wsystko gotowe!
                </h1>
                
                <div class="text-center my-8">
                    <v-icon color="success" size="96">mdi-emoticon</v-icon>
                </div>


                <v-btn
                    color="primary"
                    block
                    class="mt-2"
                    :disabled="loading"
                    @click="logout"
                    large
                >
                    Zakończ
                </v-btn>
            </v-sheet>
        </div>
    </div>
</template>

<script>
export default {
    
    data(){
        return {
            loading: false
        }
    },

    methods: {
        async logout(){
            if(this.loading) return;
            this.loading = true;

            try{

                await this.axios.post("/auth/logout");
                
                this.$store.commit('unsetAuthData');
                this.$router.push("/");

            }
            catch(err){
                console.error(err);

                this.$store.dispatch('addMessage', {
                    type: 'error',
                    msg: err.response.data.msg
                });
            }

            this.loading = false;
        }
    }
}
</script>