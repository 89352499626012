<template>
    <div class="leads-page">
        <div class="leads-page__inner">
            <v-sheet
                rounded="lg"
                color="white"
                class="pa-8"
            >
                <h1 class="text-h5 font-weight-medium">
                    Leady wymagające manualnej obsługi
                </h1>
                <div>
                    Wykryte duplikaty i/lub leady, których dodawanie zakończyło się błędem
                </div>
 
                
                <div class="my-8">

                    <div 
                        class="mb-8 pb-8"
                        style="border-bottom: 1px solid #ddd"
                        v-for="(item, ix) in $store.state.LEADS_REJECTED"
                        :key="ix"
                    >
                        <div style="display: flex; justify-content: space-between; align-items: center;" class="mb-4">
                            <div class="text-h6">
                                #{{ ix + 1 }}
                            </div>
                            <v-btn
                                icon
                                color="success"
                                @click="resolve(ix)"
                            >
                                <v-icon>mdi-check</v-icon>
                            </v-btn>
                        </div>
                        <v-text-field 
                            :value="item.person_email"
                            readonly
                            label="Adres e-mail"
                            outlined
                            hide-details
                            class="mb-6"
                            @click="copyText(item.person_email)"
                        />
                        <v-text-field 
                            :value="item.person_name"
                            readonly
                            label="Imię i nazwisko"
                            outlined
                            hide-details
                            class="mb-6"
                            @click="copyText(item.person_name)"
                        />

                        <div class="font-weight-bold">
                            Wiadomość:
                        </div>
                        <iframe :key="'if_' + ix" :ref="'iframe_' + ix" frameborder="0" style="display: block; width: 100%; height: 350px; background-color: #fff;"></iframe>
                    </div>

                </div>    
                
                <v-btn
                    block
                    large
                    color="success"
                    class="mt-8"
                    @click="proceed"
                    :disabled="$store.state.LEADS_REJECTED.length > 0"
                >
                    Kontynuuj
                </v-btn>
            </v-sheet>
        </div>
    </div>
</template>

<script>
export default {
    
    data(){
        return {
            
        }
    },


    methods: {
        proceed(){
            this.$store.state.LEADS_REJECTED = [];
            this.$router.push('/done');
        },

        async copyText(txt){

            try{
                await this.$copyText(txt);
                this.$store.dispatch('addMessage', {
                    type: 'success',
                    msg: 'Skopiowano!'
                });
            }
            catch(err){
                console.error(err);
            }

        },

        mountIframes(){
            for(let i = 0; i < this.$store.state.LEADS_REJECTED.length; i++){
                let el = this.$refs['iframe_' + i];
                if(Array.isArray(el)) el = el[0];

                el.contentWindow.document.open();
                el.contentWindow.document.close();
                el.contentWindow.document.write(this.$store.state.LEADS_REJECTED[i].message);
            }
        },

        resolve(ix){
            this.$store.state.LEADS_REJECTED.splice(ix, 1);
            this.$nextTick(this.mountIframes);
        }
    },

    mounted(){
        this.$nextTick(this.mountIframes);
    }
}
</script>