<template>
    <div class="leads-page">
        <div class="leads-page__inner">
            <v-sheet
                rounded="lg"
                color="white"
                class="pa-8"
            >
                <h1 class="text-h5 font-weight-medium">
                    Filtrowanie leadów
                </h1>
                <div>
                    Przejrzyj przesłane leady i zdecyduj, czy dodać je do Pipedrive
                </div>

                <div class="my-8 rounded-lg lime lighten-5 pa-4" v-if="currentLead">
                    <h2 class="text-h6 font-weight-medium mb-4">
                        Lead #{{ counter }} z {{ max_count }}
                    </h2>
                    <div class="mt-1">
                        <span class="font-weight-medium">
                            Adres e-mail:
                        </span>
                        {{ currentLead.person_email }}
                    </div>
                    <div class="mt-1">
                        <span class="font-weight-medium">
                            Imię i nazwisko:
                        </span>
                        {{ currentLead.person_name || 'b./d.' }}
                    </div>
                    <h6 class="text-body-1 font-weight-medium mt-1">
                        Wiadomość:
                    </h6>
                    <iframe ref="iframe" frameborder="0" style="display: block; width: 100%; height: 450px; background-color: #fff;"></iframe>
                    
                    <v-row :gutter="32" class="mt-4">
                        <v-col cols="6">
                            <v-btn
                                color="error"
                                block
                                @click="rejectLead"
                                large
                            >
                                <v-icon>mdi-close-circle</v-icon>
                                <span class="ml-1">Odrzuć</span>
                            </v-btn>

                        </v-col>
                        <v-col cols="6">
                            
                            <v-btn
                                color="success"
                                block
                                @click="acceptLead"
                                large
                            >
                                <v-icon>mdi-check-circle</v-icon>
                                <span class="ml-1">Zaakceptuj</span>
                            </v-btn>
                        </v-col>
                    </v-row>
                </div>
                <v-btn
                    v-else-if="!uploading"
                    block
                    large
                    color="success"
                    class="mt-8"
                    @click="startUpload"
                >
                    Prześlij zaakceptowane Leady do Pipedrive
                </v-btn>
                <div v-else-if="uploading" class="mt-8">
                    <h2 class="text-h6 font-weight-medium text-center mb-2">
                        Trwa przesyłanie leadów ({{ upload_count }}/{{ $store.state.LEADS_ACCEPTED.length }})
                    </h2>
                    <v-progress-linear
                        color="success"
                        height="12"
                        :value="uploadProgress"
                    ></v-progress-linear>

                    <div class="mt-2 text-right text-caption">
                        Leady do ręcznej weryfikacji: {{ duplicates_count }}
                    </div>
                </div>

            </v-sheet>
        </div>
    </div>
</template>

<script>
export default {
    
    data(){
        return {
            counter: 1,
            max_count: 1,

            uploading: false,
            upload_count: 0,
            duplicates_count: 0

        }
    },

    computed: {
        currentLead(){
            if(this.$store.state.LEADS_UNFILTERED != null && this.$store.state.LEADS_UNFILTERED.length > 0){
                return this.$store.state.LEADS_UNFILTERED[0];
            }
            return null;
        },
        uploadProgress(){
            const perc = Math.ceil(this.upload_count / this.$store.state.LEADS_ACCEPTED.length * 100);
            if(perc > 100) return 100;
            return perc;
        }
    },

    watch: {
        currentLead(){
            if(this.currentLead != null){
                this.handleIframe();
            }
        }
    },


    methods: {
        acceptLead(){
            this.$store.state.LEADS_ACCEPTED.push(
                this.$store.state.LEADS_UNFILTERED.splice(0, 1)[0]
            );
            this.counter += 1;
        },
        rejectLead(){
            this.$store.state.LEADS_UNFILTERED.splice(0, 1);
            this.counter += 1;
        },

        handleIframe(){
            const el = this.$refs['iframe'];
            el.contentWindow.document.open();
            el.contentWindow.document.close();
            el.contentWindow.document.write(this.currentLead.message);
        },

        waitForMs(ms){
            return new Promise((resolve, reject) => setTimeout(resolve, ms));
        },

        async startUpload(){
            if(this.uploading || this.$store.state.LEADS_ACCEPTED.length == 0) return;
            this.uploading = true;
            this.upload_count = 0;

            let ix = 0;
            for(ix; ix < this.$store.state.LEADS_ACCEPTED.length; ix++){
                
                try{
                    const rr = await this.axios.post("/pipedrive/add-lead", {
                        person_email: this.$store.state.LEADS_ACCEPTED[ix].person_email,
                        person_name: this.$store.state.LEADS_ACCEPTED[ix].person_name,
                        content: this.$store.state.LEADS_ACCEPTED[ix].message
                    });
    
                    if(rr.data.success !== true){
                        // lead nie został dodany - duplikat
                        this.$store.state.LEADS_REJECTED.push(
                            JSON.parse(JSON.stringify(this.$store.state.LEADS_ACCEPTED[ix]))
                        );
                        this.duplicates_count += 1;
                    }
                    else{
                        window.c = rr.data.c;
                    }

                }
                catch(err){
                    console.error(err);
                    this.$store.dispatch('addMessage', {
                        type: 'error',
                        msg: 'Lead #' + (ix + 1) + ' nie został dodany z powodu błędu aplikacji'
                    });

                    // w przyapdku błędu też wrzucamy do odrzuconych do ręcznej zabawy
                    this.$store.state.LEADS_REJECTED.push(
                        JSON.parse(JSON.stringify(this.$store.state.LEADS_ACCEPTED[ix]))
                    );
                    this.duplicates_count += 1;
                }

                await this.waitForMs(200);
                this.upload_count += 1;
            }

            this.$store.state.LEADS_UPLOADED = true;
            this.$store.state.LEADS_UNFILTERED = null;
            this.uploading = false;

            if(this.$store.state.LEADS_REJECTED.length > 0){
                this.$router.push("/rejected-leads");
            }
            else{
                this.$router.push("/done");
            }
        }
    },

    mounted(){
        if(this.$store.state.LEADS_UNFILTERED != null){
            this.max_count = this.$store.state.LEADS_UNFILTERED.length;

            this.$nextTick(this.handleIframe);
        }
    }
}
</script>